export default function Greeting() {
    const today = new Date();
    let hours = today.getHours();
    var greeting;

    if (hours >= 0 && hours < 12) {
        greeting = "Good Morning"
    } else if (hours >= 12 && hours < 16) {
        greeting = "Good Afternoon"
    } else if (hours >= 16) {
        greeting = "Good evening"
    }
    return greeting
}