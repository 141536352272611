import { BsTrashFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import DjangoDateTime from "./elements/DjangoDateTime";

export function SidebarCollectionCard(props) {
    
    return (
        <>
            <Link to={"/collections/" + props.data.unique_id}>
            <div class="flex flex-row mb-2 border-gray-400">
            <div class="transition duration-500 shadow ease-in-out transform hover:-translate-y-1 hover:shadow-lg select-none cursor-pointer bg-white  rounded-md flex flex-1 items-center p-4">
                {props.shared ? 
                <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                    <div class="relative block">
                        <FaUsers class="mx-auto object-cover rounded-full h-10 w-10 "/>
                    </div>
                </div> : null }
                <div class="flex w-full pl-1 md:mr-10">
                    <div class="w-full font-medium dark:text-gray-800">
                    {props.data.name}
                    </div>
                    {//<div class="text-sm text-gray-600 dark:text-gray-500">
                     //   Developer
                    //</div>
                    }
                </div>
                <div class="text-xs text-gray-600 dark:text-gray-600">
                    <DjangoDateTime time={props.data.date_updated} />
                </div>
                <button class="flex justify-end w-12 text-right">
                    <svg width="12" fill="currentColor" height="12" class="text-gray-500 hover:text-gray-800 dark:hover:text-white dark:text-gray-200" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                        </path>
                    </svg>
                </button>
                </div>
            </div>
            </Link>
            
        </>
    )
}