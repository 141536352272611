/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { HiXCircle } from 'react-icons/hi'
import { GrClose } from 'react-icons/gr'
import { Navigate } from 'react-router-dom'
import { Button } from '@mui/material'

export default function SmallNotification(props) {
  const [show, setShow] = useState(true)
  if(props.redirect){
      return <Navigate to="/" />
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                      {props.status === 'error' &&
                    <HiXCircle className="h-6 w-6 text-red-400" aria-hidden="true"/>}
                    {props.status === 'ok' &&
                    <BsFillCheckCircleFill className="h-6 w-6 text-green-400" aria-hidden="true"/>}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5" >
                    <p  data-testid="message" className="text-sm font-medium text-gray-900">{props.message}</p>
                    <p data-testid="detail" className="mt-1 text-sm text-gray-500">{props.detail}</p>
                    <div className='flex justify-end'>
                    {props.link &&
                    <a href={props.link} color='inherit' className="hover:text-green-600">{props.link_text}</ a>
                    }</div>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <a
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <GrClose className="h-5 w-5" aria-hidden="true" />
                    </ a>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
