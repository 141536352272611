import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { isExpired } from "react-jwt";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import DocumentMeta from 'react-document-meta'
import CollectionHome from "../views/collections/CollectionHome";
import CreateCollection from "../views/collections/CreateCollection";
import ListPasswords from "../views/collections/ListPasswords";
import CreatePassword from "../views/collections/CreatePassword";
import { Alert, Slide, Snackbar } from "@mui/material";
import CollectionActivity from "../views/collections/CollectionActivty";
import InstanceView from "../views/collections/InstanceView";
import CreateInstance from "../views/collections/CreateInstance";


const meta = {
    title: "Collections | Nomadic Dashboard",
    description: "View all collections",
    canonical: "https://passmd.online/collections",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "password"
      }
    }
  };
  

const Collections =() => {
    let location = useLocation();
    const [cookies] = useCookies([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [refetch, setFetch] = useState(false)
    const [showAlert, setShow] = useState(false)
    const isTokenExpired = isExpired(cookies['pdid'])

    let success = searchParams.get("success")
    let fetch = searchParams.get("refetch")
    let message = searchParams.get("message")
    
    useEffect(() => {
      if (success) {
        setShow(true)
      }
      if (fetch){
        setFetch(true)
      }
    }, [success, fetch])

    function handleCloseAlert() {
      searchParams.delete("success")
      searchParams.delete("message")
      searchParams.delete("refetch")
      setSearchParams(searchParams)
      setShow(false)
      setFetch(false)
    }
    function TransitionDown(props){
      return <Slide {...props} direction="down" />;
    }

    if (isTokenExpired || !cookies['pdid']) {
        sessionStorage.clear()
        return <Navigate to={"/auth/signin"} state={{ from: location }} />
    }
    
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
        <Snackbar
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
            open={showAlert} autoHideDuration={5000} onClose={() => {handleCloseAlert()}}>
            <Alert  onClose={() => {handleCloseAlert()}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: 'rgb(22 101 52)' }}>
            {message}
            </Alert>
        </Snackbar >
        <Navbar />
        <div className="flex flex-row flex-wrap bg-gray-50">
            <Sidebar fetch={refetch} />
            <div className="flex-shrink max-w-screen-xl md:max-w-screen-2xl h-screen  items-center md:p-2 md:w-3/5 overflow-auto  rounded-l-xl">
            
            { /* Passwords Go here */}
                <Routes>
                   <Route exact path="" element={<CollectionHome />} />
                   <Route path="create" element={<CreateCollection />} />
                   <Route path=":cn" element={<ListPasswords />} />
                   <Route path="instance/:is" element={<InstanceView />} />
                   <Route path=":cn/create-password" element={<CreatePassword />} />
                   <Route path=":cn/create-instance" element={<CreateInstance />} />
                   <Route path=":cn/activity" element={<CollectionActivity />} />
                </Routes>
            </div>
        </div>
        </>
    )
}

export default Collections