import { Avatar } from "@mui/material"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useCookies } from "react-cookie"
import Loading from "./Loading"

const queryClient = new QueryClient()

const menu = [
    {"name": "Collections", "href": "/collections"},
    {"name": "Profile", "href": "/profile"},
    {"name": "Settings", "href": "/profile"}
]

export default function AvatarDropdown() {
    return(
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    )
}

function App() {
    const [cookies] = useCookies([])
    const [open, setOpen] = useState(false)

    const { isLoading, error, data } = useQuery(['me'], () => 
        fetch(process.env.REACT_APP_BACKEND_URL +'/users/me', {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        }).then(res => res.json())
    )

    function handleClick(){
        setOpen(!open)
    }

    if (isLoading) {
        return (
            <p>Loading...</p>
        )
    }
    if (error) {
        return (
            <p>Error.</p>
        )
    }
    

    return (
        <>
        <div class="inline-block text-left">
            <div className="flex flex-row items-center space-x-2">
                {data.first_name ? <>
                <p  className="text-sm">{data.first_name}</p>
                <a href="/collections">
                <Avatar sx={{ width: 25, height: 25, bgcolor: "gray", fontSize: 12 }}>{data.first_name[0].toUpperCase() + data.last_name[0].toUpperCase()}</Avatar>
                </a> </>  : null }
            </div>
            {open &&
            <div class="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <div class="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {menu.map((item, idx) => (
                    <a href={item.href} key={idx} class="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                        <span class="flex flex-col">
                            <span>
                               {item.name}
                            </span>
                        </span>
                    </a>))}
                    
                </div>
            </div>}
        </div>


        </>
    )
}

