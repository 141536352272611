const months = new Map([
    ["01", "January"],
    ["02", "Febuary"],
    ["03", "March"],
    ["04", "April"],
    ["05", "May"],
    ["06", "June"],
    ["07", "July"],
    ["08", "August"],
    ["09", "September"],
    ["10", "October"],
    ["11", "November"],
    ["12", "December"]
    ])

    
const days = new Map([
    [0, "Sunday"],
    [1, "Monday"],
    [2, "Tuesday"],
    [3, "Wednesday"],
    [4, "Thursday"],
    [5, "Friday"],
    [6, "Saturday"]
    ])



export default function DjangoDateTime(props){
    const today = new Date()
    let offSet = today.getTimezoneOffset() / 60

    var diff

    let date = props.time.split("T")[0]
    let time = props.time.split("T")[1].split(".")[0]
    
    let h = parseInt(time.split(":")[0])
    let m = time.split(":")[1]
   
    
    const year = date.split("-")[0]
    const month = date.split("-")[1]
    const day = date.split("-")[2]

    const propsDate = new Date(date)

    if (offSet < 0){
        if (h + Math.abs(offSet) >= 24){
            diff = h + Math.abs(offSet)
            h = diff - 24
        }else {
            h = h + Math.abs(offSet)
        }
        if (h < 10) {
            h = "0" + h
        }
        time = `${h}:${m}`
    }else {
        if (h - offSet < 0){
            diff = h - offSet
            h = 24 + diff
        }else {
            h = h - offSet
        }
        if (h < 10) {
            h = "0" + h
        }
        time = `${h}:${m}`
    }
    

    if (propsDate.getDate() === today.getDate() && 
        propsDate.getMonth() === today.getMonth() &&
        propsDate.getFullYear() === today.getFullYear()) {
            return "Today " + time
        }
    else if (propsDate.getDate() === today.getDate() - 1 && 
        propsDate.getMonth() === today.getMonth() &&
        propsDate.getFullYear() === today.getFullYear()) {
            let whatDay = `${days.get(propsDate.getDay())} ${time}` 
            return whatDay
        } 
        
    let formattedDate = `${day}, ${months.get(month)} ${year}.`
    
    return formattedDate
 
}