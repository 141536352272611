import { Link } from "react-router-dom";

export default function Breadcrumb(props){
    return (
        <header aria-label="Page Header">
            <div class="max-w-screen-xl ml-2 sm:px-4 sm:py-4 ">
                <div class="sm:flex sm:items-start sm:justify-between">
                <div class="text-center sm:text-left">
                    <nav aria-label="breadcrumb" className="w-full text-gray-800">
                        <ol className="flex h-8 space-x-2 ">
                            <li className="flex items-center">
                                <a rel="noopener noreferrer" href="/collections" title="Back to collections" className="flex items-center hover:underline">Collections</a>
                            </li>
                            {props.list ? props.list.map((item, idx) => (
                            idx + 1 === props.list.length ? 
                            <li key={idx} className="flex items-center space-x-1">
                                <span className="text-green-400">/</span>
                                <p className="flex items-center px-1 capitalize hover:underline">{item.name}</p>
                            </li>
                            :
                            <li key={idx} className="flex items-center space-x-1">
                                <span className="text-green-400">/</span>
                                <Link rel="noopener noreferrer" to={item.href} className="flex items-center px-1 capitalize hover:underline">{item.name}</Link>
                            </li>)) : null}
                            
                        </ol>
                    </nav>
                </div>
                </div>
            </div>
            </header>
        
    )
}