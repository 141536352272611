import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import {TextField } from "@mui/material";
import * as Yup from "yup";

import logo from "../assets/img/icon.png"
import DocumentMeta from 'react-document-meta'
import SmallNotification from "../components/elements/SmallNotofication";


const meta = {
  title: "Get OTP",
  description: "Resend otp to email",
  canonical: "https://passmd.online/auth/get-otp",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "phis,public,health,information"
    }
  }
};

const EmailSchema = Yup.object().shape({
    email: Yup.string()
    .lowercase()
    .matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "Invalid Email address")
    .max(200)
    .required(),
    
})

const GetOtp = () => {
    

    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState('')
    const [isemail, setEmail] = useState(false)
    const [iseerror, setEmailError] = useState('')
    const [fetcherror, setFError] = useState('')
    const [show, setShow] = useState(false)
    const [eshow, setEShow] = useState(false)

    
    function handleSubmit(values,  { setSubmitting }){
        fetch(process.env.REACT_APP_BACKEND_URL + '/otp', {
            method: 'PUT',
            mode: 'cors',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setSubmitting(false)
                setRedirect(true)
            }else{
                setSubmitting(false)
                setFError("Something wrong happened, Please try again")
                setShow(true)
                
            }
        })
        .catch(function(error){
            setFError("Server Error")
            setShow(true)
            setSubmitting(false)
        })
    }
    

    if(redirect){
        return <Navigate to='/auth/success' />
    }
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
        {show &&
        <button className="top-0 w-full" onClick={() => {setShow(false)}}>
            <SmallNotification  status="error" message="Error" detail={fetcherror} />
        </button>}
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-md md:mx-auto">
              <div >
                <div className='flex flex-col justify-center items-center'>
                <a
                    href="/"
                    className=" w-auto"
                  >
                    <img src={logo} alt="passmd" className="h-10" />        
                  </a>
                  </div>
                  <>
                  <h2 className="font-sans font-bold text-black mx-auto text-center text-2xl">Resend OTP</h2>
                  <p className="text-sm text-center">Enter your email</p> 
                  <Formik
                    initialValues={{
                        email: "",
                        }
                    }
                    validationSchema={EmailSchema}
                    onSubmit={handleSubmit}
                    >
                  {props => (<form onSubmit={props.handleSubmit} className="mt-4 space-y-6 ">
                    <div >
                        <div >
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                type="text"
                                variant="outlined"
                                required
                                autoFocus
                                fullWidth
                                value={props.values.email}
                                onChange={props.handleChange}
                                error={props.touched.email && Boolean(props.errors.email)}
                            />
                        </div>
                    </div>
                    <div className="flex">
                    <button 
                        type="submit"
                        disabled={props.isSubmitting}
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-900 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                        >
                            {!props.isSubmitting && "Submit"}
                            {props.isSubmitting && 
                            <p className="animate-pulse">Loading...</p>}
                    </button>
                    
                    </div>
                </form>)}
                </Formik>
                </>
              </div>
          </div>
        </div>
      </div>
      </>
    )
}
export default GetOtp