import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { isExpired } from "react-jwt";
import { useParams } from "react-router-dom";
import DjangoDateTime from "../../components/elements/DjangoDateTime";
import { MdUpdate } from "react-icons/md";
import CollectionToolbar from "../../components/CollectionToolbar";

const queryClient = new QueryClient()

function CollectionActivity() {
    return (
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    )
}

function App() {
    let {cn} = useParams()
    const [cookies] = useCookies([])
    const isTokenExpired = isExpired(cookies['pdid'])

    const { isLoading, error, data } = useQuery(['activity', cn], () =>
        fetch(process.env.REACT_APP_BACKEND_URL + `/collections/${cn}/activity`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        }).then(res => res.json())
    )
    

    if (isLoading) return "Loading"
    if (error) return "Error loading resource"

    return (
        <>
            <CollectionToolbar 
                list={[
                    {name: data.collection_name, href: `/collections/${cn}`},
                    {name: "Activity", href: `/collections/${cn}/activity`}
                ]} 
                cn={cn} />
            {data.ok ? data.data.length > 0 ?
            <>
                <div class="relative mx-auto my-20 h-96 sm:h-fit w-full max-w-md md:max-w-2xl p-4 overflow-auto  ">
                    
                    {data.data.map((activity, idx) => (
                    <div key={idx} class="flex items-start justify-between mb-6 rounded">
                        {activity.status === "create" &&
                        <span class="p-2 text-white bg-green-300 rounded-full ">
                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                                </path>
                            </svg>
                        </span>}
                        {activity.status === "update" &&
                        <span class="p-2 text-white bg-indigo-300 rounded-full ">
                            <MdUpdate className="font-bold" />
                        </span>}
                        {activity.status === "delete" &&
                        <span class="p-2 text-white bg-red-300 rounded-full ">
                            <svg width="20" height="20" class="m-auto text-red-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                                </path>
                            </svg>
                        </span>}
                        <div class="flex items-center justify-between w-full">
                            <div class="flex flex-col items-start justify-between w-full ml-2 text-sm">
                                <p class="text-gray-700 ">
                                    <span class="mr-1 font-bold">
                                        {activity.user_name}
                                    </span>
                                    {activity.activity}
                                </p>
                                <p class="text-gray-300">
                                    <DjangoDateTime time={activity.date} />
                                </p>
                            </div>
                        </div>
                    </div>))}
                </div> 
            </>
            : null : null}
        </>
    )
}

export default CollectionActivity