import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { BsCollection, BsKeyFill, BsPlus } from "react-icons/bs"
import { Link } from "react-router-dom";
import CollectionCardSkeleton from "./elements/CollectionCardSkeleton";
import { SidebarCollectionCard } from "./SidebarCollectionCard";

const queryClient = new QueryClient();

function Sidebar(props){
    return(
        <QueryClientProvider client={queryClient}>
            <App fetch={props.fetch} />
        </QueryClientProvider>
    )
}
function App(props) {
    const [cookies] = useCookies([]);

    useEffect(() => {
        
        if (props.fetch){
          refetch()
        }
      }, [props.fetch])

    const { isLoading, error, data, refetch } = useQuery(['collections'], () => 
        fetch(process.env.REACT_APP_BACKEND_URL + '/collections',
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }

        }).then(res => res.json())
    )
    if (isLoading) return <CollectionCardSkeleton/>
    if (error) return "Could not load resource"

    return (
        <>
           <div className="hidden md:flex flex-col max-h-full p-3 w-72 text-black">
                <div class="flex flex-col sm:flex-row sm:justify-around">
                    <div class="h-full w-72">
                        <div class="flex items-center justify-start mt-3">
                            <Link to="/collections" class=" text-2xl md:text-4xl font-thin tracking-wider"> 
                                Collections
                            </Link>
                        </div>
                        <nav class="mt-10">
                           {data.data.length > 0 ? data.data.map((collection) => (
                                <SidebarCollectionCard data={collection} shared={false}/>
                           )) : 
                           <div className="flex flex-col items-start text-gray-100 opacity-70">
                            <p className="font-bold text-lg ">No Collections</p>
                            <p className="text-xs">add a collection to get started</p>
                           </div>}
                           {data.shared.length > 0 ? data.data.map((collection) => (
                                <SidebarCollectionCard data={collection} shared={true} />
                           )) :null}
                        </nav>
                        <div class="absolute bottom-0 mt-10 mb-2 flex">
                            <Link to={"/collections/create"} class="py-2 px-4 flex text-md justify-center items-center  bg-green-800 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                <BsPlus className="" />
                                New Collection
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar