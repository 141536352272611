import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BsFillPencilFill } from "react-icons/bs";
import { MdUpdate } from "react-icons/md";
import { Link, Navigate } from "react-router-dom";
//import CollectionActivity from "./CollectionActivity";
import Breadcrumb from "./elements/Breadcrumb";
import DjangoDateTime from "./elements/DjangoDateTime";
import SmallNotification from "./elements/SmallNotofication";
import EditCollection from "./forms/EditCollection";
import CollaboratorCard from "./CollaboratorCard";

export default function CollectionToolbar(props) {
    const [edit, setEdit] = useState(false)
    const [link, setLink] = useState(false)
    const [share, setShare] = useState(false)
    const [error, setError] = useState(false)
    const [activity, setActivity] = useState(false)
    const [open, setOpen] = useState(false);
    const [redirect, setRedirect] = useState(false)
    const [activityData, setData] = useState(null)
    const [copy, setCopy] = useState(false)
    const [cookies] = useCookies([])

    
    const handleCloseActivity = () => {
        setActivity(false)
        setData(null)
    };
    const handleOpenShare = () => setShare(true);
    const handleCloseShare = () => setShare(false);
    const handleOpenLink = () => setLink(true);
    const handleCloseLink = () => {setCopy(false); setLink(false)};
    const handleOpenEdit = () => setEdit(true);
    const handleCloseEdit = () => setEdit(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    useEffect(() => {
        //console.log("tb: ", props)
        if (props.modal) {
            setEdit(false)
        }
    }, [props.modal])
    
    function onClickCopy(text) {
        setCopy(true)
        navigator.clipboard.writeText(text)
    }

    function onClickDelete() {
        fetch(process.env.REACT_APP_BACKEND_URL + "/collections/" + props.cn, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        })
        .then(res => res.json())
        .then((data) => {
            if (data.ok) {
                handleClose()
                setRedirect(true)
            } else {
                setError(true)
            }
        })
        .catch(function(error) {
            setError(true)
        })
    }

    function handleOpenActivity() {
        fetch(process.env.REACT_APP_BACKEND_URL + `/collections/${props.cn}/activity`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        }).then(res => res.json())
        .then((data) => {
            if (data.ok) {
                setData(data.data)
                setActivity(true)
            }
        })
    }

    if (redirect) {
        return (
            <Navigate to={`/collections?success=true&message=Collection%20deleted%20successfully&refetch=true`} />
        )
    }

    return (
        <>
         { error &&
        <button onClick={() => {setError(false)}}>
          <SmallNotification status="error" message="Error" detail="Sorry you can't do this" />  
        </button>
         }

        {/* LINK MODAL */}
        <Modal
            open={link}
            onClose={handleCloseLink}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        
        <div class="object-center w-72 p-4 mx-auto my-20 bg-white shadow-lg rounded-2xl ">
            <div class="w-full h-full text-center">
                <div class="flex flex-col justify-between h-full">
                    <svg width="40" height="40" className="w-12 h-12 m-auto mt-4 text-green-600" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                            </path>
                        </svg>
                    <p class="mt-4 text-xl font-bold text-gray-800 ">
                        Shareable Link
                    </p>
                    <p class=" py-2 text-xs text-gray-600 ">
                        This collection can be accessed via this link. <br />
                        It is a private link and only people you invite as collaborators can view this collection.
                    </p>
                    <p className="mt-6 text-xs font-semibold text-blue-700 hover:underline">
                            {process.env.PUBLIC_URL + '/collections/' + props.cn}
                    </p>
                    <div class="flex items-center justify-between w-full gap-4 mt-8">
                        <button
                            disabled={copy}
                            onClick={() => onClickCopy(process.env.PUBLIC_URL + '/collections/' + props.cn)}
                            type="button" class="py-2 px-4  bg-green-800 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            {copy ? "Copied" : "Copy Link"}
                        </button>
                        {/*<
                        button type="button" class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-green-500 focus:ring-offset-green-200 text-green-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Cancel
                        </button> 
                        */}
                    </div>
                </div>
            </div>
        </div>
        </Modal>
        
        {/* EDIT MODAL */}
        <Modal
            open={edit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <EditCollection cn={props.cn} name={props.list[0].name} />
        </Modal>

        {/* DELETE MODAL */}
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        
        <div class="object-center w-64 p-4 mx-auto my-20 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
            <div class="w-full h-full text-center">
                <div class="flex flex-col justify-between h-full">
                    <svg width="40" height="40" class="w-12 h-12 m-auto mt-4 text-red-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                        </path>
                    </svg>
                    <p class="mt-4 text-xl font-bold text-gray-800 dark:text-gray-200">
                        Delete Collection
                    </p>
                    <p class="px-6 py-2 text-xs text-gray-600 dark:text-gray-400">
                        Are you sure you want to delete this collection ?
                    </p>
                    <div class="flex items-center justify-between w-full gap-4 mt-8">
                        <button
                            onClick={() => onClickDelete()}
                            type="button" class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Delete
                        </button>
                        {/*<
                        button type="button" class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-green-500 focus:ring-offset-green-200 text-green-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Cancel
                        </button> 
                        */}
                    </div>
                </div>
            </div>
        </div>
        </Modal>

        {/* ACTIVITY MODAL */}
        <Modal
            open={activity}
            onClose={handleCloseActivity}
            >
                <div class="relative mx-auto my-20 h-96 sm:h-fit w-full max-w-md md:max-w-2xl p-4 overflow-auto bg-white shadow-lg rounded-xl  ">
                    <div class="flex sticky items-center justify-between w-full mb-3 sm:mb-8">
                        <p class="text-xl font-normal text-gray-800 ">
                            Activity
                        </p>
                        <Link to={`/collections/${props.cn}/activity`} class="flex items-center text-sm text-gray-300 border-0 hover:text-gray-600  focus:outline-none">
                            VIEW ALL
                        </Link>
                    </div>
                    {activityData ? 
                    activityData.slice(0,6).map((activity, idx) => (
                    <div key={idx} class="flex items-start justify-between mb-6 rounded">
                        {activity.status === "create" &&
                        <span class="p-2 text-white bg-green-300 rounded-full ">
                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                                </path>
                            </svg>
                        </span>}
                        {activity.status === "update" &&
                        <span class="p-2 text-white bg-indigo-300 rounded-full ">
                            <MdUpdate className="font-bold" />
                        </span>}
                        {activity.status === "delete" &&
                        <span class="p-2 text-white bg-red-300 rounded-full ">
                            <svg width="20" height="20" class="m-auto text-red-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                                </path>
                            </svg>
                        </span>}
                        <div class="flex items-center justify-between w-full">
                            <div class="flex flex-col items-start justify-between w-full ml-2 text-sm">
                                <p class="text-gray-700 ">
                                    <span class="mr-1 font-bold">
                                        {activity.user_name}
                                    </span>
                                    {activity.activity}
                                </p>
                                <p class="text-gray-300">
                                    <DjangoDateTime time={activity.date} />
                                </p>
                            </div>
                        </div>
                    </div>)) : null}
                </div>
        </Modal>
        
        {/* SHARE MODAL */}
        <Modal
            open={share}
            onClose={handleCloseShare}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <CollaboratorCard collection={props.cn} />
        </Modal>

        <div class="block mt-1 md:hidden">
            <Breadcrumb list={props.list} />
        </div>
        <header class="z-40 flex items-center justify-between w-full h-16 bg-white">
            <div class="hidden mt-1 md:block">
                <Breadcrumb list={props.list} />
            </div>
            <div class="relative flex flex-col justify-end h-full px-3">
                <div class="relative flex items-center justify-end w-full p-1 space-x-4">
                    <button onClick={() => handleOpenLink()} class="flex items-center p-2 text-gray-400 bg-white rounded-full shadow hover:text-green-700 text-md">
                        <svg width="20" height="20" class="" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                            </path>
                        </svg>
                    </button>
                    <button onClick={() => handleOpenActivity()} class="flex items-center p-2 text-gray-400 bg-white rounded-full shadow hover:text-green-700 text-md">
                        <MdUpdate />
                    </button>
                    <button onClick={() => handleOpenEdit()} class="flex items-center p-2 text-gray-400 bg-white rounded-full shadow hover:text-green-700 text-md">
                        <BsFillPencilFill />
                    </button>
                    <button onClick={() => handleOpen()} class="flex items-center p-2 text-gray-400 bg-white rounded-full shadow hover:text-red-700 text-md">
                    <svg width="20" height="20" class="" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                        </path>
                    </svg>
                    </button>
                    <span class="w-1 h-8 bg-gray-200 rounded-lg">
                    </span>
                    <button
                        onClick={() => handleOpenShare()}
                        className="ml-2 h-10 inline-flex items-center px-4  border border-transparent text-sm font-medium rounded-md shadow-lg text-green-900 bg-white hover:text-white hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                    share
                  </button>
                </div>
            </div>
        </header>
       
        </>
    )
}