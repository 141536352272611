import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Formik, Field, Form } from "formik";
import { isExpired, useJwt } from "react-jwt"
import { useCookies } from "react-cookie";

import DocumentMeta from 'react-document-meta';

import SmallNotification from '../../components/elements/SmallNotofication';
import Breadcrumbs from '../../components/elements/Breadcrumb';


const meta = {
  title: "Create Collection | PassMD",
  description: "Create a new collection",
  canonical: "https://passmd.online/collections/create",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "passmd"
    }
  }
};

const CreateCollection = () => {
    let location = useLocation()

    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState(null)
    const [show, setShow ] = useState(false)
    const [ver, setVer ] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [token, setToken] = useState('')
    const [warning, setWarning] = useState('')
    const [ cookies, setCookie ] = useCookies([]);
    const isTokenExpired = isExpired(cookies['pdid'])

    function validateName(value){
        if (!value) {
            setError('*Required');
          } else if (value.length < 3) {
            setError('Min. of 3 characters');
          } else if (value.length > 150) {
            setError('Max of 150 characters');
          } else {
            setError(null)
          }
         return error;

    }
    

    function handleSubmit (values, { setSubmitting }) {
        fetch(process.env.REACT_APP_BACKEND_URL + '/collections', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            },
            body: JSON.stringify(values, null, 2),
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setRedirect(true)
            }
            else if (!data.ok && data.message === "Duplicate"){
                setWarning("Collection already exists")
                setSubmitting(false)
                setShow(true)
            }
            else if (!data.ok && data.message === "Unauthorized") {
                setWarning("You are not allowed to do this")
                setSubmitting(false)
                setShow(true)
            }
            else if (!data.ok){
                setSubmitting(false)
                setWarning(data.message)
                setShow(true)
            }   
        })
        .catch(function (error){
            setSubmitting(false)
            setWarning("Server is unavailable. Please try again later.")
            setShow(true)
        }) 
    } 
    
    if (isTokenExpired){
        return <Navigate to='/auth/signin' state={{ from: location }} />
    }
    if(redirect){
        return <Navigate to="/collections?success=true&message=Collection%20created%20successfully&refetch=true" replace={true}
         />
    }
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
         { show && Boolean(warning) &&
        <button onClick={() => {
            setWarning("")
            setShow(false)
          }}>
          <SmallNotification status="error" message="Error" detail={warning} />  
        </button>
         }
         <Breadcrumbs list={[{href: "/collections/create", name: "create collection"}]} />
        <div className="container mx-auto px-4 h-full">
          <div className="flex justify-center items-center content-center ">
            <div className="relative flex flex-col  min-w-0 break-words w-full  p-6 m-2 border-0 md:max-w-screen-xl md:mx-auto">
              <div >
              
                <h2 className="font-sans font-bold text-black mx-auto text-start text-3xl">Create collection</h2>
            
                {/*Signin form */}
                <Formik
                    initialValues={{
                        name: ''
                    }}
                    
                    onSubmit={handleSubmit}
                >
            
            {({ isSubmitting, errors, touched }) => (
                
                <Form className="mt-8 space-y-6 max-w-md justify-center">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="name" className="sr-only">Collection Name</label>
                            <Field name="name" placeholder="Collection name" 
                            className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                            autoComplete="name"
                            validate={validateName}
                            />
                                {errors.name && touched.name &&
                                    <div className="text-red-500 font-sans text-xs border-0 mb-2">
                                        {errors.name}
                                        </div>
                                }
                            
                        </div>
                    </div>
                    <button 
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-900 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                        disabled={isSubmitting}>
                        {!isSubmitting && "Submit"}
                        {isSubmitting && <p className="animate-pulse">Loading..</p>}
                    </button>
                </Form>
            )}
        </Formik>
              </div>
          </div>
        </div>
      </div>
      </>
    )
}
export default CreateCollection