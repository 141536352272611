import { Field, Formik, Form } from "formik"
import { useState } from "react";
import { useCookies } from "react-cookie";
import { BiServer } from "react-icons/bi";
import { BsEyeFill, BsEyeSlashFill, BsPersonSquare, BsServer, BsStars } from "react-icons/bs";
import { isExpired } from "react-jwt";
import { Navigate, useLocation, useParams } from "react-router-dom"
import Breadcrumbs from "../../components/elements/Breadcrumb";
import SmallNotification from "../../components/elements/SmallNotofication";

const selectButtonVariants = {
    0: "block w-6 h-6 bg-green-800 rounded-full",
    1: "block w-6 h-6 bg-blue-800 rounded-full",
    2: "block w-6 h-6 bg-pink-800 rounded-full",
    3: "block w-6 h-6 bg-violet-800 rounded-full",
    4: "block w-6 h-6 bg-teal-800 rounded-full"

}
const colorButtons = [
    {value: "green"},
    {value: "blue"},
    {value: "pink"},
    {value: "violet"},
    {value: "teal"}
]

const URLPlaceholders = {
    "Virtual Machine": "IP Address",
    "Database": "Host",
    "Personal": "Account URL",
    "Other": "URL"
}

const userPlaceholders = {
    "Virtual Machine": "Username",
    "Database": "User",
    "Personal": "Username",
    "Other": "Username"
}

const categories = [
    {name: "Virtual Machine", icon: <BiServer className="text-7xl " />},
    {name: "Database", icon: <BsServer className="text-7xl" />},
    {name: "Personal", icon: <BsPersonSquare className="text-7xl" />},
    {name: "Other", icon: <BsStars className="text-7xl" />}
]


function CreatePassword() {
    let {cn} = useParams()
    let location = useLocation()
    const [cookies] = useCookies([])
    const [redirect, setRedirect] = useState(false)
    const [show, setShow ] = useState(false)
    const [warning, setWarning] = useState('')
    const [category, setCategory] = useState('')
    const [activeButton, setActiveButton] = useState(0)
    const [inputType, setInputType] = useState("password")
    const isTokenExpired = isExpired(cookies['pdid'])
    
    const breadcrumbs = [
        {name: "Create Password"}
    ]

    const nameValidation = value => {
        let errorMessage;
        if (!value) {
            errorMessage = 'required';
        } else if (value.length > 100) {
           errorMessage = "Max 100 characters"
        }
        return errorMessage;
        };
      
    const categoryValidation = value => {
        let errorMessage;
        if (!value) {
            errorMessage = 'Category is required';
        
        return errorMessage;
        };
    }

    const usernameValidation = value => {
        let errorMessage;
        if (!value) {
            errorMessage = 'required';
        } else if (value.length > 100) {
            errorMessage = "Max 100 characters"
        }
        return errorMessage;
        };

    const passwordValidation = value => {
        let errorMessage;
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/.test(value)) {
            errorMessage = 'Password must contain special character, Capital Letter and number';
        } 
        return errorMessage;
        };

    const descValidation = value => {
        let errorMessage;
        if (value.length > 500) {
            errorMessage = 'Max 500 character';
        }
        return errorMessage;
        };
    
    function handleCategorySelect(category, v) {
        setCategory(category)
        v.category = category
    }
    function handleClick(idx, v) {
        v.color = colorButtons[idx].value
        setActiveButton(idx)
    }

    function handleSubmit (values, { setSubmitting }) {
        fetch(process.env.REACT_APP_BACKEND_URL + '/passwords', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            },
            body: JSON.stringify(values, null, 2),
            credentials: 'same-origin'
        })
        .then(res => res.json())
        .then((data) => {
            if (data.ok) {
                setRedirect(true)
            }
            else if (!data.ok && data.message === "Duplicate"){
                setWarning("Duplicate password name")
                setSubmitting(false)
                setShow(true)
            }
            else if (!data.ok && data.message === "Unauthorized") {
                setWarning("You are not allowed to do this")
                setSubmitting(false)
                setShow(true)
            }
            else if (!data.ok && data.message === "NotFound") {
                setWarning("Collection not found")
                setSubmitting(false)
                setShow(true)
            }
            else if (!data.ok){
                setSubmitting(false)
                setWarning(data.message)
                setShow(true)
            }   
        })
        .catch(function (error){
            setSubmitting(false)
            setWarning("Server is unavailable. Please try again later.")
            setShow(true)
        }) 
        
    }

    function onClickShow() {
        if (inputType === "password") {
            setInputType("text")
        } else {
            setInputType("password")
        }
    }

    if(redirect){
        return <Navigate to={`/collections/${cn}?success=true&message=Password%20created%20successfully`} replace={true}
         />
    }

    if (isTokenExpired || !cookies['pdid']) {
        return <Navigate to={'/auth/signin'} state={{ from: location }} />
    }
    return (
        <>
        { show && Boolean(warning) &&
        <button onClick={() => {
            setWarning("")
            setShow(false)
          }}>
          <SmallNotification status="error" message="Error" detail={warning} />  
        </button>
         }
         <Breadcrumbs list={breadcrumbs} />
         <div className="bg-white">
            <div className="max-w-2xl mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4">
                <h2 className="text-xl font-medium text-gray-900">Add password</h2>
                </div>
                <div class="flex items-center justify-center">
                    <div class="w-full">
                        <div class="flex flex-col justify-center p-10 bg-white  card">
                            <div class="prod-img">
                                    <Formik
                                        initialValues={{
                                            collection_id: cn,
                                            name: '',
                                            user_name: '',
                                            password: '',
                                            category: '',
                                            description: '',
                                            url: '',
                                            color: 'green'
                                        }}
                                        
                                        onSubmit={handleSubmit}
                                    >
                                
                                {({ isSubmitting, errors, touched, values }) => (
                                    <>
                                    {category === "" &&
                                    <>
                                    <h2 className="text-lg font-medium text-gray-900">Select a category</h2>
                                    <p className="text-xs text-gray-600">What are you creating a password for?</p>
                                    <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                                    {categories.map((category, idx) => (
                                        <div key={idx} className="relative group">
                                        <button 
                                            onClick={() => handleCategorySelect(category.name, values)}
                                            className="w-40 h-40 flex flex-col items-start rounded-lg overflow-hidden bg-white hover:bg-green-700 text-green-800 hover:text-white text-center border-2 border-t-8  border-green-900 transition duration-500 shadow ease-in-out transform hover:-translate-x-1 hover:shadow-lg select-none cursor-pointer">
                                            <div className="flex items-end mt-3">
                                                <div className="w-full bg-green-800  backdrop-filter backdrop-blur py-2 px-4 rounded-r-md text-sm font-medium text-gray-100 text-center">
                                                    {category.name}
                                                </div>
                                            </div>
                                            <div className="flex flex-col w-full items-center p-3">
                                                <div>
                                                    {category.icon}
                                                </div>
                                                <p className="text-xs"></p>
                                            </div>
                                        </button>
                                        </div>
                                    ))}
                                    </div>
                                    </>}
                                    
                                    {category !== "" &&
                                    <Form className="mt-4 space-y-6">
                                        <div className="rounded-md  space-y-px">
                                            <div>
                                                <label htmlFor="category" className="sr-only">Category</label>
                                                <Field as="select" name="category" 
                                                className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                                required
                                                validate={categoryValidation}
                                                >
                                                    <option value="Virtual Machine">Virtual Machine</option>
                                                    <option value="Database">Database</option>
                                                    <option value="Personal">Personal</option>
                                                    <option value="Other">Other</option>
                                                </Field>
                                            </div>
                                            <div>
                                                <label htmlFor="name" className="sr-only">Name</label>
                                                <Field name="name" placeholder="Password name" 
                                                className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                                autoComplete="name"
                                                validate={nameValidation}
                                                required
                                                />
                                                    {errors.name && touched.name &&
                                                        <div className="text-red-500 font-sans text-xs mb-2">
                                                            {errors.name}
                                                            </div>
                                                    }
                                                
                                            </div>
                                            <div>
                                                <label htmlFor="user_name" className="sr-only">{`${userPlaceholders[values.category]}`}</label>
                                                <Field name="user_name" placeholder={`${userPlaceholders[values.category]}`}
                                                className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                                autoComplete="name"
                                                validate={usernameValidation}
                                                required
                                                />
                                                    {errors.name && touched.name &&
                                                        <div className="text-red-500 font-sans text-xs mb-2">
                                                            {errors.name}
                                                            </div>
                                                    }
                                                
                                            </div>
                                            <div>
                                                <label htmlFor="url" className="sr-only">IP Address</label>
                                                <Field name="url" placeholder={`${URLPlaceholders[values.category]}`}
                                                className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                                autoComplete="name"
                                                required
                                                />
                                                    {errors.name && touched.name &&
                                                        <div className="text-red-500 font-sans text-xs mb-2">
                                                            {errors.name}
                                                            </div>
                                                    }
                                                
                                            </div>
                                            <div className="flex w-full ">
                                                <label htmlFor="password" className="sr-only">Password</label>
                                                <Field name="password" placeholder="Password" 
                                                className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                                autoComplete="password"
                                                type={`${inputType}`}
                                                required
                                                />
                                            
                                                    {errors.password && touched.password &&
                                                        <div className="text-red-500 font-sans text-xs mb-2">
                                                            {errors.password}
                                                            </div>
                                                  }
                                                <div className="m-1">
                                                    {inputType === "password" ?
                                                    <button onClick={() => onClickShow()} type="button" class="py-1 px-1 flex justify-center items-center  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  w-10 h-10 rounded-lg ">
                                                        <BsEyeFill />
                                                    </button> :
                                                    <button onClick={() => onClickShow()} type="button" class="py-1 px-1 flex justify-center items-center  bg-white text-green-600  focus:ring-green-500 focus:ring-offset-green-200 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  w-10 h-10 rounded-lg ">
                                                        <BsEyeSlashFill />
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="sr-only">Description</label>
                                                <Field name="description" placeholder="Description" 
                                                className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                                autoComplete="description"
                                                validate={descValidation}
                                                multiline
                                                />
                                                    {errors.description && touched.description &&
                                                        <div className="text-red-500 font-sans text-xs mb-2">
                                                            {errors.description}
                                                            </div>
                                                    }
                                                
                                            </div>
                                            
                                            <div> 
                                            <div>
                                                <label  className="">Color</label>
                                                <ul class="flex flex-row items-center justify-center">
                                                    {colorButtons.map((color, idx) => (
                                                        idx === activeButton ?
                                                        <li class="mr-4 last:mr-0">
                                                            <span class="block p-1 transition duration-300 ease-in border-2 border-gray-500 rounded-full">
                                                                <button type="button" onClick={() => handleClick(idx, values)} className={`${selectButtonVariants[idx]}`}>
                                                                </button>
                                                            </span>
                                                        </li> :

                                                        <li class="mr-4 last:mr-0">
                                                        <span class="block p-1 transition duration-300 ease-in border-2 border-white rounded-full">
                                                            <button type="button" onClick={() => handleClick(idx, values)} className={`${selectButtonVariants[idx]}`}>
                                                            </button>
                                                        </span>
                                                        </li>
                                                    ))
                                                    }
                                                    
                                                </ul>
                                            </div>
                                            </div>
                                            
                                        </div>
                                        <button 
                                            type="submit"
                                            className=" uppercase transition duration-200 ease-in py-2 px-4 flex text-md justify-center items-center  bg-green-800 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                            disabled={isSubmitting}>
                                            {!isSubmitting && "Submit"}
                                            {isSubmitting && <p className="animate-pulse">Loading..</p>}
                                        </button>
                                    </Form>}
                                </>)}
                            </Formik>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>

    )
}

export default CreatePassword