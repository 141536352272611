import React, { useState, useRef, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import {TextField } from "@mui/material";
import * as Yup from "yup";
import axios from "axios"

import logo from "../assets/img/icon.png"
import DocumentMeta from 'react-document-meta'
import SmallNotification from "../components/elements/SmallNotofication";


const meta = {
  title: "Email Verification",
  description: "Verify your email",
  canonical: "https://passmd.online/auth/otp",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "phis,public,health,information"
    }
  }
};

const SignupSchema = Yup.object().shape({
    number_1: Yup.number()
    .min(0)
    .max(9)
    .required(),
    number_2: Yup.number()
    .min(0)
    .max(9)
    .required(),
    number_3: Yup
    .number()
    .min(0)
    .max(9)
    .required(),
    number_4: Yup
    .number()
    .min(0)
    .max(9)
    .required(),
    
})

function useQuery(){
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search])
}
const Otp = () => {
    let query = useQuery();
    let email = query.get("email")
    const MAX_LENGTH = 1;
    
    const inputTwoRef = useRef(null)
    const inputThreeRef = useRef(null)
    const inputFourRef = useRef(null)

    const [redirect, setRedirect] = useState(false)
    const [error, setError] = useState('')
    const [isemail, setEmail] = useState(false)
    const [iseerror, setEmailError] = useState('')
    const [fetcherror, setFError] = useState('')
    const [show, setShow] = useState(false)
    const [eshow, setEShow] = useState(false)

    useEffect(() => {
        const input = inputFourRef.current;
        
      }, []);

    const onChangeFocus = (e, ref) =>  {
        if (e.target.value.length === MAX_LENGTH) {
            ref.current.focus();
        }
        
    }
    
    function handleSubmit(values,  { setSubmitting }){
        fetch(process.env.REACT_APP_BACKEND_URL + '/otp', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "email": email,
                "otp": `${values["number_1"]}${values["number_2"]}${values["number_3"]}${values["number_4"]}` }, 
                null, 2)
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setSubmitting(false)
                setRedirect(true)
            }else{
                setSubmitting(false)
                setFError(data.message)
                setShow(true)
                
            }
        })
        .catch(function(error){
            setFError("Server Error")
            setShow(true)
            setSubmitting(false)
        })
    }
    

    if(redirect){
        return <Navigate to='/auth/verification-success' />
    }
    return (
        <>
        <div>
          <DocumentMeta {...meta} />
        </div>
        {show &&
        <button className="top-0 w-full" onClick={() => {setShow(false)}}>
            <SmallNotification  status="error" message="Error" detail={fetcherror} />
        </button>}
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg shadow-lg p-6 m-4 bg-gray-100 border-0 md:max-w-md md:mx-auto">
              <div >
                <div className='flex flex-col justify-center items-center'>
                <a
                    href="/"
                    className=" w-auto"
                  >
                    <img src={logo} alt="passmd" className="h-10" />        
                  </a>
                  </div>
                  {email ?
                  <>
                  <h2 className="font-sans font-bold text-black mx-auto text-center text-2xl">Email Verification</h2>
                  <p className="text-sm text-center">A 4-digit OTP has been sent to your email  {email}</p> 
                  <Formik
                    initialValues={{
                        email: email ? email : null,
                        number_1: '',
                        number_2: '',
                        number_3: '',
                        number_4: '',}
                    }
                    validationSchema={SignupSchema}
                    onSubmit={handleSubmit}
                    >
                  {props => (<form onSubmit={props.handleSubmit} className="mt-2 space-y-6 ">
                    <div className="flex justify-center space-x-2">
                        <div className="w-12">
                            <TextField
                                id="number_1"
                                name="number_1"
                                type="text"
                                variant="outlined"
                                required
                                autoFocus
                                sx={{ flex: 1, fontStyle: "normal", color: "#010101", fontSize: 17, textAlign: "center", outline: 1, outlineColor: "#00695f" }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 1, textAlign: "center" }}
                                value={props.values.number_1}
                                onChange={props.handleChange}
                                onInput={(e) => {onChangeFocus(e, inputTwoRef)}}
                                error={props.touched.number_1 && Boolean(props.errors.number_1)}
                            />
                        </div>
                        <div className="w-12">
                            <TextField
                                id="number_2"
                                name="number_2"
                                type="text"
                                variant="outlined"
                                required
                                inputRef={inputTwoRef}
                                sx={{ flex:1, fontFamily: "serif", fontWeight: 10, textAlign: "center" }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 1 }}
                                value={props.values.number_2}
                                onChange={props.handleChange}
                                onInput={(e) => {onChangeFocus(e, inputThreeRef)}}
                                error={props.touched.number_2 && Boolean(props.errors.number_2)}
                            />
                        </div>
                    
                    <div className="w-12">
                        <TextField
                            id="number_3"
                            name="number_3"
                            type="text"
                            variant="outlined"
                            required
                            inputRef={inputThreeRef}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 1 }}
                            value={props.values.number_3}
                            onChange={props.handleChange}
                            onInput={(e) => {onChangeFocus(e, inputFourRef)}}
                            error={props.touched.number_3 && Boolean(props.errors.number_3)}
                            />
                        {/*eshow && <p className="text-sm font-light text-red-600 ml-3">Email is not avaible</p>*/}
                    </div>
                    <div className="w-12">
                        <TextField
                            id="number_4"
                            name="number_4"
                            type="text"
                            variant="outlined"
                            required
                            inputRef={inputFourRef}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 1 }}
                            value={props.values.number_4}
                            onChange={props.handleChange}
                            error={props.touched.number_4 && Boolean(props.errors.number_4)}
                        />
                    </div>
                    </div>
                    <div className="flex">
                        
                    <button 
                        type="submit"
                        disabled={props.isSubmitting}
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-900 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                        >
                            {!props.isSubmitting && "Submit"}
                            {props.isSubmitting && 
                            <p className="animate-pulse">Loading...</p>}
                    </button>
                    
                    </div>
                </form>)}
                </Formik>
               
                <div className="text-center text-sm ">
                    <hr className="mt-6 mb-6 border-green-800"></hr>
                    <div className="mt-3 text-sm">
                    <a href="/auth/get-otp"><span className="font-medium text-sm text-green-900 hover:text-green-600">Resend otp</span></a>
                    </div>
                </div>
                </>
                : 
                <p>Invalid Request</p>}
                
              </div>
          </div>
        </div>
      </div>
      </>
    )
}
export default Otp