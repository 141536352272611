import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { FaBatteryEmpty, FaGlassMartini } from "react-icons/fa"
import { Link, Navigate, useLocation, useParams, useSearchParams } from "react-router-dom"
import { BsCollection, BsKeyFill, BsPlus } from "react-icons/bs";
import { Alert, Card, CardContent, Popover, Slide, Snackbar } from "@mui/material";
import { MdPassword } from "react-icons/md";
import PasswordCard from "../../components/PasswordCard"
import { isExpired } from "react-jwt"
import CollectionToolbar from "../../components/CollectionToolbar"
import ErrorPage from "../../layouts/ErrorPage"
import InstanceCard from "../../components/InstanceCard"

const queryCient = new QueryClient()

function ListPasswords() {
    return (
        <QueryClientProvider client={queryCient}>
            <App />
        </QueryClientProvider>
    )
}

function App(){
    let location = useLocation()
    let {cn} = useParams()
    const [cookies] = useCookies([])
    const isTokenExpired = isExpired(cookies['pdid'])
    const [searchParams, setSearchParams] = useSearchParams();
    const [show, setShow] = useState(false)

    let success = searchParams.get("success")
    let refetchM = searchParams.get("refetch")
    let message = searchParams.get("message")
    let modal = searchParams.get("modal")

    const [anchorEl, setAnchorEl] = useState(null);
    
    const popoverMenu = {
        default: "flex items-center space-x-2 px-1 py-2 text-md text-gray-800 hover:bg-green-800 hover:text-white hover:rounded-md"
    }

    useEffect(() => {
        if (success) {
          setShow(true)
        }
        if (refetchM){
          refetch()
        }
      }, [success, refetchM])

    

    const { isLoading, error, data, refetch } = useQuery(['password', cn], () => 
        fetch(process.env.REACT_APP_BACKEND_URL + `/collections/${cn}/passwords`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        }).then(res => res.json())
    )

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const id = open ? "new-popover" : undefined

    function handleCloseAlert() {
        searchParams.delete("success")
        searchParams.delete("message")
        searchParams.delete("refetch")
        searchParams.delete("modal")
        setSearchParams(searchParams)
        setShow(false)
        return (
            <Navigate to={'/collection/' + cn} />
        )
    }

    function TransitionDown(props){
        return <Slide {...props} direction="down" />;
    }

    if (isLoading) {
        return "Loading"
    }
    if (error) {
        return "could not load resource"
    }

    if (isTokenExpired || !cookies['pdid']) {
        sessionStorage.clear()
        return <Navigate to={'/auth/signin'} state={{ from: location }} />
    }
    return (
        
        <>
        <Snackbar
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
            open={show} autoHideDuration={5000} onClose={() => {handleCloseAlert()}}>
            <Alert icon={false} onClose={() => {handleCloseAlert()}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: 'rgb(22 101 52)' }}>
            {message}
            </Alert>
        </Snackbar>
        <CollectionToolbar 
            list={[{name: data.collection_name, href: `/collections/${cn}`}]} 
            cn={cn} 
            modal={modal} />
        {data.data ? data.data.length > 0 ?
        <>
        <div className="grid grid-cols-2 gap-2 md:gap-4 lg:gap-6 xl:gap-10 xl:grid-cols-3 mt-4">
            {data.data.map((password, idx) => (
                password.category === "bts-instance" || password.category === "ran-instance" ?
                <InstanceCard data={password} key={idx} cn={cn} modal={modal}/> :
                <PasswordCard data={password} key={idx} cn={cn} modal={modal} />
            ))}
        </div>
        <div className="absolute bottom-0 right-0 m-4 md:m-10">
        <button 
            color="success" 
            aria-describedby={id}
            className="flex items-center p-3 text-xl  transition ease-in duration-200 uppercase rounded-full bg-green-900 hover:bg-green-600 hover:text-white focus:outline-none"
            onClick={handleClick}
            >
            <BsPlus className="text-3xl text-white font-bold"/>
        </button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
            >
            <Card sx={{ minWidth: 130, minHeight: 120 }}>
                <CardContent>
                    <div class="flex w-full text-left">
                        <div class=" bg-white text-black">
                            <div class="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <Link to={`/collections/${cn}/create-instance`} href="#" class={`${popoverMenu["default"]}`} role="menuitem">
                                    <BsCollection />
                                    <span class="flex flex-col">
                                        <span>
                                            OpenBTS instance
                                        </span>
                                    </span>
                                </Link>
                                
                                <Link to={`/collections/${cn}/create-password`} href="#" class={`${popoverMenu["default"]}`} role="menuitem">
                                    <BsKeyFill />
                                    <span class="flex flex-col">
                                        <span>
                                            Password
                                        </span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Popover>
    </div>
    </>
         : 
        <section className="text-gray-800 t">
            <div className="container mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:max-w-3xl">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#417505" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v11zM9.9 16.1L14 12M9.9 11.9L14 16"/></svg>
                <h1 className="text-4xl font-bold lead sm:text-5xl">No 
                    <span className="text-green-800"> Instance</span> found
                </h1>
                <p className="px-8 mt-4 mb-3 text-lg">Add a NomadicBTS instance to this collection to get started</p>
                <div className="flex flex-wrap justify-center">
                    <Link to={`/collections/${cn}/create-instance`} className="px-8 py-3 m-2 text-lg font-semibold rounded bg-green-800 text-white hover:bg-green-600">New Instance</Link>
                </div>
            </div>
        </section>
        : <main
        className="min-h-screen bg-cover bg-top sm:bg-top"
        
      >
        <div data-testid="Er-1" className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-400 tracking-tight sm:text-5xl">
            Uh oh! I think you're lost.
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-50">
            It looks like the page you're looking for doesn't exist.
          </p>
          
        </div>
      </main> }
        </>
    )
}

export default ListPasswords