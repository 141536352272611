import { Avatar, Box, Button, Chip, IconButton, Tooltip } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import { Field, Formik, Form } from "formik";
import { useState } from "react";
import { useCookies } from "react-cookie";
import SmallNotification from "./elements/SmallNotofication";
import { BsQuestionCircle } from "react-icons/bs";
import { Navigate } from "react-router-dom";

const queryClient = new QueryClient();

function CollaboratorCard(props){
    return (
        <QueryClientProvider client={queryClient}>
            <App collection={props.collection} />
        </QueryClientProvider>
    )
}

function App(props) {
    const [collabs, setCollabs] = useState([])
    const [cookies] = useCookies([])
    const [err, setError] = useState('')
    const [show, setShow ] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [warning, setWarning] = useState('')

    const { isLoading, error, data } = useQuery(['collaborator', props.collection], () =>
        fetch(process.env.REACT_APP_BACKEND_URL + `/collections/${props.collection}/collaborators`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies['pdid']
                }
            }
        ).then(res => res.json())
    )


    

    function handleChange(e) {
        if (e.target.value.split(" ").length > 1 ) {
            if (collabs.length < 10) {
                let collaborator = e.target.value.split(" ")[0]
                if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(collaborator)){
                    setCollabs([...collabs, collaborator])
                    setError(null)
                    // Reset input
                    e.target.value = ""
                    console.log(collabs)
                } else {
                    setError('Invalid Email Address')
                    
                } 
            }else {
                setError('Max of 10 collaborators reached')
            }
        } 
    }



    function handleSubmit (values, { setSubmitting }) {
        values.collaborators = collabs
        fetch(process.env.REACT_APP_BACKEND_URL + `/collections/${props.collection}/collaborators`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            },
            body: JSON.stringify(values, null, 2),
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setSubmitting(false)
                setRedirect(true)
            }
            else if (!data.ok && data.message === "NotFound"){
                setSubmitting(false)
                setWarning("You are not allowed to perform this action")
                setShow(true)
            }
            else if (!data.ok && data.message === "FailedToSendEmail"){
                setSubmitting(false)
                setWarning("The following invites failed to send: " + data.data)
                setShow(true)
            }
            else if (!data.ok && data.message === "BadRequest"){
                setSubmitting(false)
                setWarning("Minimum 1 email address required. Email address are space seperated")
                setShow(true)
            }
            else if (!data.ok){
                setSubmitting(false)
                setWarning("Request Timeout")
                setShow(true)
            }   
        })
        .catch(function (error){
            setSubmitting(false)
            setWarning("Server is unavailable. Please try again later.")
            setShow(true)
        }) 
    }    

    if (isLoading) return "Loading"
    if (error) return "Could not load resource"

    if(redirect){
        return <Navigate to={`/collections/${props.collection}?success=true&message=Collaborator%20invites%20sent%20successfully&refetch=true&modal=true`}
         />
    }
    
    return(
        <>
        { show && Boolean(warning) &&
            <button onClick={() => {
                setWarning("")
                setShow(false)
              }}>
              <SmallNotification status="error" message="Error" detail={warning} />  
            </button>
             }
        <div class="object-center max-w-screen-sm mx-auto p-4 my-20 overflow-hidden bg-white shadow-lg rounded-xl ">
            {data.data.length > 0 ?
                <>
                <p class="mb-6 text-xl  font-medium text-gray-800 ">
                    Collaborators
                </p>
                <div class="grid grid-cols-3 lg:grid-cols-6 gap-1">
                {data.data.map((collab, idx) => (
                <div class="flex flex-col items-center">
                    <div key={idx} class="relative">
                        <Avatar sx={{ width: 40, height: 40 }}>
                        {collab.first_name[0].toUpperCase() + collab.last_name[0].toUpperCase()}
                        </Avatar>
                        <svg width="10" height="10" fill="currentColor" class="absolute bottom-0 right-0 w-4 h-4 p-1 -mx-1 -my-1 text-white bg-blue-600 rounded-full fill-current" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z">
                            </path>
                        </svg>
                    </div>
                    <span class="mt-2 text-xs text-gray-600">
                        {collab.first_name + ' ' + collab.last_name}
                    </span>
                </div> 
                ))}
                </div>
                </>
                : null}
            
            <div className="flex flex-row items-center space-x-2">
            <p class="mt-4 mb-6 text-xl  font-medium text-gray-800 ">
                Add Collaborators
            </p>
            <Tooltip title="You can add a maximum of 10 collaborators at a time">
                <button className="mt-4 mb-6 text-sm">
                    <BsQuestionCircle />
                </button>
            </Tooltip>
            </div>
            <Formik
                initialValues={{
                    collaborators: collabs,
                    access_type: "view"
                }}
                
                onSubmit={handleSubmit}
            >
                
                {({ isSubmitting, errors, touched, values }) => (
                    
                    <Form 
                        className="mt-4 space-y-6  justify-center">
                        <>
                        <div className="space-y-2">
                               <div className="grid grid-cols-3 gap-2 w-full">
                                {collabs.length >= 0 ? 
                                collabs.map((value, idx) => (
                                    <div key={idx} className="col-span-1 whitespace-normal">
                                        <span class="px-4 py-2 flex  items-center w-full text-base  rounded-md text-gray-600 border bg-gray-200 border-gray-500">
                                            <p className="text-ellipsis overflow-hidden ... w-full">{value}</p>
                                            <button 
                                                onClick={() => {
                                                    setCollabs(collabs.filter(c => c !== value))
                                                }} 
                                                className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="ml-4 hover:text-gray-900" viewBox="0 0 1792 1792">
                                                    <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </span>
                                    </div>
                                ))  : null}
                                </div>
                                <div>
                                <label htmlFor="email" className="sr-only">Email</label>
                                <Field 
                                    name="email" placeholder="Email address" 
                                    type="text"
                                    className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm"
                                    
                                    onChange={(e) => handleChange(e)}
                                />
                                    {err ?
                                        <div className="text-red-500 font-sans text-xs border-0 mb-2">
                                            {err}
                                            </div>
                                    : null}
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <label htmlFor="access_type" className="text-sm">Collaborator Permission</label>
                                    <Field 
                                        name="access_type"
                                        as="select"
                                        className="appearance-none rounded-lg my-1 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-800 focus:border-green-800 focus:z-10 sm:text-sm">
                                            <option value="view">Only View Passwords</option>
                                            <option value="edit">Create and Edit Passwords</option>
                                        </Field>
                                </div>
                            
                        </div>
                        <button 
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-900 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                            disabled={isSubmitting}>
                            {!isSubmitting && "Invite collaborators"}
                            {isSubmitting && <p className="animate-pulse">Please wait..</p>}
                        </button>
                        </>
                    </Form>
                )}
            </Formik>
        </div>
        </>
    )
}

export default CollaboratorCard