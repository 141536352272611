import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "../components/elements/Loading.js";
import Signin from "../views/Signin";
import bg from "../assets/img/Auth-bg.jpeg"
import Signup from "../views/Signup.js";
import Otp from "../views/Otp.js";
import GetOtp from "../views/GetOtp.js";
import SuccessPage from "../views/SuccessPage.js";
import VerificationSuccess from "../views/VerfifcationSuccess.js";

//components

// const FooterSmall = lazy(() => import("../../components/Footer/FooterSmall"));

const Errorpage = lazy(() => import("../layouts/ErrorPage"));


export default function Auth(){
    return (
        <>
        <Suspense fallback={<Loading />}>
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full md:h-full"
                        style={{
                            backgroundImage:
                              `url(${bg})`,
                          }}
                    ></div>
                        <Routes>
                            <Route path="signin" element={<Signin />} />
                            <Route path="*"  element={<Errorpage />} />
                            <Route path="signup" element={<Signup />} />
                            <Route path="otp" element={<Otp />} />
                            <Route path="get-otp" element={<GetOtp />} />
                            <Route path="success" element={<SuccessPage />} />
                            <Route path="verification-success" element={<VerificationSuccess />} />
                            {/*<Route path="reset-password" element={<DevResetPassword />} />
                            <Route path="forgot-password/:id" element={<DevForgotPassword />} />
                            <Route path="generate-verify-link" element={<DevGenerateVerifyLink />} />
                            
                            
                            <Redirect from="/auth" to="/auth/signin" /> */}
                        </Routes>
                    
                </section>
            </main>
        </Suspense>
        </>
    )
}