import { useParams, useSearchParams } from "react-router-dom";
import CollectionToolbar from "../../components/CollectionToolbar";
import StatCards from "../../components/elements/StatsCards";
import InstanceToolbar from "../../components/InstanceToolbar";

function InstanceView(props){

    const [searchParams, setSearchParams] = useSearchParams();
    let {is} = useParams()
    let modal = searchParams.get("modal")

    return (
        <>
            <InstanceToolbar
                list={[{name: `Instance`, href: `/collections`},{name: `${is}`, href: `/collections/instance/${is}`}]} 
                cn={is} 
                modal={modal} 
            />
            <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"> 
                <StatCards />
            </div>
            <div class="grid grid-cols-1 p-2 row-gap-8 space-x-4 md:grid-cols-2"> 
                <div class="w-full bg-white shadow-lg rounded-2xl ">
                    <div class="flex items-center justify-between p-4 border-b-2 border-gray-100">
                        <p class="font-bold text-black text-md ">
                            Quick Commands
                        </p>
                    </div>
                    <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                        <p class="flex items-center  ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                        <span className="ml-2">Initialize OpenBTS</span>
                        </p>
                        <div class="flex items-center">
                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                run
                            </span>
                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded hover:text-green-800">
                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                    <g fill="none">
                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                        </path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                        <p class="flex items-center  ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                        <span className="ml-2">List Subscribers (tmsis)</span>
                        </p>
                        <div class="flex items-center">
                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                run
                            </span>
                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded hover:text-green-800">
                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                    <g fill="none">
                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                        </path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                        <p class="flex items-center  ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                        <span className="ml-2">UHD Find Devices</span>
                        </p>
                        <div class="flex items-center">
                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                run
                            </span>
                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded hover:text-green-800">
                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                    <g fill="none">
                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                        </path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                        <p class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>
                            <span className="ml-2">UHD USRP Devices</span>
                        </p>
                        <div class="flex items-center">
                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                run
                            </span>
                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded hover:text-green-800">
                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                    <g fill="none">
                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                        </path>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="flex items-center justify-between p-4">
                        <p class="font-bold text-black text-md ">
                            Execute Command
                        </p>
                        <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 hover:text-green-800 rounded">
                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                <g fill="none">
                                    <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                    </path>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div class="flex w-full items-center px-1 py-2 text-gray-600 bg-blue-100 border-l-4 rounded-b-2xl border-blue-800 dark:bg-blue-500">   
                        
                            <input type="text" id="rounded-email" class="w-full rounded-lg border-transparent appearance-none border border-gray-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent" placeholder="Enter command here"/>
                        
                    </div>
                </div>

                <div class="w-full p-4 bg-white shadow-lg rounded-2xl">
                    <p class="font-bold text-black text-md ">
                       Subscribers
                    </p>
                    <p className="text-sm text-gray-500">
                        No Subscribers
                    </p>
                </div>

            </div>
            <div className="flex flex-col mt-3">
				<h4 className="text-lg font-semibold">Command Output</h4>
				<p className="dark:text-gray-400">No output at the moment. Execute a command.</p>
			</div>


        </>
    )
}

export default InstanceView