import React from "react";
import { Fragment, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { BiMenu } from 'react-icons/bi'
import { GrClose } from 'react-icons/gr'
import { isExpired } from 'react-jwt'
import { Cookies, useCookies } from 'react-cookie'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'

import logo from "../assets/img/logo.png"
import AvatarDropdown from "./elements/AvatarDropdown";

const navigation = [
  { name: 'Collections', href: '/collections', current: true },
  { name: 'NomadicBTS', href: '/nomadicbts', current: true },
  { name: 'NomadicRAN', href: '/nomadicran', current: true },
  { name: 'Documentation', href: '/developer/docs', current: true },
  { name: 'API', href: '/developer/api', current: true }
]

const publicNavigation = [
  { name: 'Sign Up', href: '/auth/signup' },
  { name: 'Sign In', href: '/auth/signin' },
  { name: 'Back to Fedgen', href: '/phis'}
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  let location = useLocation();
  const [ cookies, removeCookie ] = useCookies([])
  const [redirect, setRedirect] = useState(false);
  const  istokenExpired  = isExpired(cookies['pdid'])
  
  const logout = () => {
    document.cookie = "pdid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    sessionStorage.clear()
    setRedirect(true)
  }
  if (redirect) return <Navigate to='/auth/signin' />
 
  return (
    <>
      
      {/*Logged out*/}
     
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-white shadow-sm lg:static lg:overflow-y-visible'
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-full mx-auto h-12 px-4 py-1 sm:px-6 lg:px-8 ">
              <div className="relative flex  xl:grid xl:grid-cols-12 lg:gap-8">
                <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2 -mt-1">
                  <div className="flex-shrink-0 flex items-center space-x-2">
                    <a href="/">
                      <img
                        className="block h-12 w-auto"
                        src={logo}
                        alt="PassMD"
                      />
                    </a>
                  </div>
                </div>
                <div className="hidden lg:block min-w-0 ml-6 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                  <div className="flex items-center space-x-6 px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-full lg:mx-0 xl:px-0 lg:py-2">
                  <a href="/nomadicran" className="text-sm text-gray-600 hover:underline">NomadicRAN</a>
                  <a href="/nomadicbts" className="text-sm text-gray-600 hover:underline">NomadicBTS</a>
                    <a href="/collections" className="text-sm text-gray-600 hover:underline">Collections</a>
                    <a href="/docs" className="text-sm text-gray-600 hover:underline">Docs</a>
                    <a href="/api" className="text-sm text-gray-600 hover:underline">API</a>
                  </div>
                </div>
                <div className="absolute right-0 md:absolute md:right-0 md:inset-y-0 lg:hidden">
                  {/* Mobile menu button */}
                  
                  <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <GrClose className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <BiMenu className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Popover.Button>
                </div>

                {istokenExpired && <div className="hidden  lg:absolute lg:flex lg:inset-y-0 lg:right-0  lg:justify-evenly xl:col-span-4">
                  <a
                      href="/auth/signup"
                      className="ml-6 h-10 inline-flex items-center px-4 py-2 text-sm text-black hover:text-green-600  hover:underline "
                    >
                    create account
                  </a>
                  <Link 
                      to={"/auth/signin"}
                      state={{ from: location }}
                      className="ml-2 h-10 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-700 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Sign In
                    </Link>
                </div>}
                
                {!istokenExpired && 
                <div className="hidden  lg:absolute lg:flex lg:inset-y-0 lg:right-0 lg:items-center  lg:justify-center xl:col-span-4">
                  <AvatarDropdown />
                  <button
                      
                      onClick={logout}
                      className="ml-2 h-10 inline-flex items-center px-4  border border-transparent text-sm font-medium rounded-md shadow-lg text-green-900 bg-white hover:text-white hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                    Log Out
                  </button>
                  
                </div>}
              </div>
            </div>
            {/* Mobile Popup menu */}
            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                      item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                      'block rounded-md py-2 px-3 text-base font-medium'
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                  {istokenExpired && publicNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                  {!istokenExpired && 
                    
                    <button
                      key="logout"
                      onClick={logout}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      Logout
                    </button>
                  }
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}
