import { Alert, Modal, Slide, Snackbar } from "@mui/material"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { BsEyeFill, BsEyeSlashFill, BsFillPencilFill } from "react-icons/bs"
import { MdDelete } from "react-icons/md"
import { isExpired } from "react-jwt"
import { Navigate, useLocation } from "react-router-dom"
import EditPassword from "./forms/EditPassword"

const cardColorVariants = {
    green: "relative flex-shrink md:flex-shrink-0 max-w-screen-lg mx-2 mb-6 overflow-hidden bg-green-800 text-white rounded-lg shadow-lg sm:mb-0 h-56 md:h-80",
    blue: "relative flex-shrink md:flex-shrink-0 max-w-screen-lg mx-2 mb-6 overflow-hidden bg-blue-800 text-white rounded-lg shadow-lg sm:mb-0 h-56 md:h-80",
    pink: "relative flex-shrink md:flex-shrink-0 max-w-screen-lg mx-2 mb-6 overflow-hidden bg-pink-800 text-white rounded-lg shadow-lg sm:mb-0 h-56 md:h-80",
    violet: "relative flex-shrink md:flex-shrink-0 max-w-screen-xl mx-2 mb-6 overflow-hidden bg-violet-800 text-white rounded-lg shadow-lg sm:mb-0 h-56 md:h-80",
    teal: "relative flex-shrink md:flex-shrink-0 max-w-screen-lg mx-2 mb-6 overflow-hidden bg-teal-800 text-white rounded-lg shadow-lg sm:mb-0 h-56 md:h-80",
}
const buttonColorVariants = {
    green: "flex items-center p-3 text-xs lg:text-xl  transition ease-in duration-200 uppercase rounded-full bg-green-900 hover:bg-green-600 hover:text-white focus:outline-none",
    blue: "flex items-center p-3 text-xs lg:text-xl  transition ease-in duration-200 uppercase rounded-full bg-blue-900 hover:bg-blue-600 hover:text-white focus:outline-none",
    pink: "flex items-center p-3 text-xs lg:text-xl  transition ease-in duration-200 uppercase rounded-full bg-pink-900 hover:bg-pink-600 hover:text-white focus:outline-none",
    violet: "flex items-center p-3 text-xs lg:text-xl  transition ease-in duration-200 uppercase rounded-full bg-violet-900 hover:bg-violet-600 hover:text-white focus:outline-none",
    teal: "flex items-center p-3 text-xs lg:text-xl  transition ease-in duration-200 uppercase rounded-full bg-teal-900 hover:bg-teal-600 hover:text-white focus:outline-none"
}

function PasswordCard(props){
    let location = useLocation()
    let cn = props.cn
    var passId = sessionStorage.getItem("pd_id")
    const data = props.data
    const [cookies, setCookie] = useCookies([])
    const [cookie] = useCookies(['Token'])
    const [show, setShow] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [password, setPassword] = useState(null)
    const isTokenExpired = isExpired(cookies['pdid'])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (props.modal) {
            setEdit(false)
        }
    }, [props.modal])
    
    const handleCloseEdit = () => setEdit(false);

    const handleOpenEdit = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/passwords/' + props.data.unique_id, {
            method: "GET",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        })
        .then(res => res.json())
        .then((data) => {
            if (data.ok) {
                setCookie('Token', data.data, { 'path': '/', 'maxAge': 60});
                setEdit(true)
            }
        })
    };

    
    function showPassword() {
        
        if (show) {
            setShow(false)
        }
        if (cookie['Token'] && passId === props.data.unique_id) {
            setShow(true)
        } else {
            setShow(false)
            fetch(process.env.REACT_APP_BACKEND_URL + '/passwords/' + props.data.unique_id, {
                method: "GET",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': cookies['pdid']
                }
            })
            .then(res => res.json())
            .then((data) => {
                if (data.ok) {
                    setCookie('Token', data.data, { 'path': '/', 'maxAge': 60});
                    setShow(true)
                    sessionStorage.setItem("pd_id", props.data.unique_id)
                }
            })
        }
    }

    function onClickDelete() {
        fetch(process.env.REACT_APP_BACKEND_URL + "/passwords/" + data.unique_id, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        })
        .then(res => res.json())
        .then((data) => {
            if (data.ok) {
                handleClose()
                setSuccess(true)
            } else {
                setError(true)
            }
        })
        .catch(function(error) {
            setError(true)
        })
    }

    function TransitionDown(props){
        return <Slide {...props} direction="down" />;
    }

    function onClickHidePassword() {
        setPassword(null)
        setShow(false)
    }

    if (isTokenExpired) {
        return <Navigate to={'/auth/signin'} state={{ from: location }} />
    }
    
    
    return (
        <>
        
        <Snackbar
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
            open={success} autoHideDuration={5000} onClose={() => {setSuccess(false)}}>
            <Alert icon={false} onClose={() => {setSuccess(false)}} severity="success" sx={{ width: '100%', color: 'white', backgroundColor: 'rgb(22 101 52)' }}>
            Password deleted
            </Alert>
        </Snackbar>

        <Snackbar
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
            open={error} autoHideDuration={5000} onClose={() => {setError(false)}}>
            <Alert icon={false} onClose={() => {setError(false)}} severity="error" sx={{ width: '100%' }}>
            Something went wrong
            </Alert>
        </Snackbar>
    
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        
        <div class="object-center w-64 p-4 mx-auto my-20 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
            <div class="w-full h-full text-center">
                <div class="flex flex-col justify-between h-full">
                    <svg width="40" height="40" class="w-12 h-12 m-auto mt-4 text-red-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                        </path>
                    </svg>
                    <p class="mt-4 text-xl font-bold text-gray-800 dark:text-gray-200">
                        Delete Password
                    </p>
                    <p class="px-6 py-2 text-xs text-gray-600 dark:text-gray-400">
                        Are you sure you want to delete this password ?
                    </p>
                    <div class="flex items-center justify-between w-full gap-4 mt-8">
                        <button
                            onClick={() => onClickDelete()}
                            type="button" class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Delete
                        </button>
                        {/*<
                        button type="button" class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-green-500 focus:ring-offset-green-200 text-green-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Cancel
                        </button> 
                        */}
                    </div>
                </div>
            </div>
        </div>
        </Modal>

        <Modal
            open={edit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        
            <EditPassword cn={cn} data={data} /> 
        </Modal>

       
            <div key={props.key} className={`${cardColorVariants[data.color]}`}>
                <div className="px-1 md:px-2 xl:px-6 pb-6 mt-1 md:mt-6 ">
                    <span className="block mb-1 text-sm md:text-base opacity-70 tracking-widest">
                        {data.category}
                    </span>
                    <div className="flex justify-between">
                        <span className="block text-base md:text-xl font-semibold">
                            {data.name}
                        </span>
                    </div>
                    <span className="block text-xs mb-2 opacity-75">
                        {data.description}
                    </span>
                    {data.user_name ? 
                    <div className="flex justify-between">
                        <span className="block text-base md:text-lg font-semibold">
                            {data.user_name}
                        </span>
                    </div> : null}
                    {data.url ? 
                    <div className="flex justify-between">
                        <span className="block text-base xl:text-lg font-semibold">
                            {data.url}
                        </span>
                    </div> : null}
                    <div className="flex justify-between">
                        <span className="block text-base font-semibold hover:underline">
                            {(show && cookie['Token']) ? passId === props.data.unique_id ? cookie['Token'] : "******" : "******"}
                        </span>
                    </div>
                    
                </div>
                <div className="absolute flex items-center justify-start bottom-0 left-0  mt-24 md:mt-5">
                    
                    <div className="p-2 m-0 lg:m-3  rounded-xl">
                    <div className="flex items-center space-x-3">
                    {!show || passId !== props.data.unique_id ? 
                        <button onClick={() => showPassword()} className={`${buttonColorVariants[props.data.color]}`}>
                             <BsEyeFill /> 
                        </button>
                        : 
                        <button onClick={() => onClickHidePassword()} className={`${buttonColorVariants[props.data.color]}`}>
                             <BsEyeSlashFill />
                        </button>}
                        <button onClick={handleOpenEdit} type="button"  className={`${buttonColorVariants[props.data.color]}`}>
                            <BsFillPencilFill />
                        </button>
                        <button 
                            onClick={handleOpen}
                            type="button"  className={`${buttonColorVariants[props.data.color]}`}>
                            <MdDelete />
                        </button>
                    </div>
                </div>
                </div>     
            </div>
        </>
    )
}

export default PasswordCard