
function StatCards(){
    return (
        <>
        <div class="grid grid-cols-2 row-gap-8 space-x-4 md:grid-cols-4"> 
            <div class="p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
                <div class="flex items-center">
                    <span class="relative p-4 bg-gray-200 rounded-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="2"></circle><path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path></svg>
                    </span>
                    <p class="ml-2 text-black text-md dark:text-white">
                        Band
                    </p>
                </div>
                <div class="flex flex-col justify-start">
                    <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
                        0
                        <span class="text-sm">
                            MHz
                        </span>
                    </p>
                    <div class="flex items-center text-sm text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M9 18l6-6-6-6"/></svg>
                        <button>
                            Change
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
                <div class="flex items-center">
                    <span class="relative p-4 bg-gray-200 rounded-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="2"></circle><path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path></svg>
                    </span>
                    <p class="ml-2 text-black text-md dark:text-white">
                        AFRCN
                    </p>
                </div>
                <div class="flex flex-col justify-start">
                    <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
                        0
                        
                    </p>
                    <div class="flex items-center text-sm text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M9 18l6-6-6-6"/></svg>
                        <button>
                            Change
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
                <div class="flex items-center">
                    <span class="relative p-4 bg-gray-200 rounded-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
                    </span>
                    <p class="ml-2 text-black text-md dark:text-white">
                        Power
                    </p>
                </div>
                <div class="flex flex-col justify-start">
                    <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
                        0
                        <span class="text-sm">
                            db
                        </span>
                    </p>
                    <div class="flex items-center text-sm text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M9 18l6-6-6-6"/></svg>
                        <button>
                            Change
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
                <div class="flex items-center">
                    <span class="relative p-4 bg-gray-200 rounded-xl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/></svg>
                    </span>
                    <p class="ml-2 text-black text-md dark:text-white">
                        Subscribers
                    </p>
                </div>
                <div class="flex flex-col justify-start">
                    <p class="my-4 text-4xl font-bold text-left text-gray-700 dark:text-gray-100">
                        0
                        <span class="text-sm">
                            Users
                        </span>
                    </p>
                    <div class="flex items-center text-sm text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#05792b" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        <button>
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default StatCards