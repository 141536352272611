import { BsPlus } from "react-icons/bs";

export default function CollectionCardSkeleton(){
    return (
        <div className="flex flex-col max-h-full p-3 w-72 bg-green-50 text-gray-800 border-r-2 border-r-green-800 ">
                <div class="flex flex-col sm:flex-row sm:justify-around">
                    <div class="h-full w-72">
                        <div class="flex items-center justify-start p-3 mt-3">
                            <span class="text-gray-800 dark:text-gray-800 text-2xl md:text-4xl font-thin tracking-wider">
                                Collections
                            </span>
                        </div>
                        
                        <nav class="mt-10   ">
                        <div class="h-24 mx-auto border-b-2 border-green-100 rounded-md w-60">
                            <div class="flex flex-row items-center justify-center h-full space-x-5 animate-pulse">
                                <div class="flex flex-col space-y-3">
                                    <div class="h-6 bg-gray-300 rounded-md w-36 ">
                                    </div>
                                    <div class="w-24 h-3 bg-gray-300 rounded-md ">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-24 mx-auto border-b-2 border-green-100 rounded-md w-60">
                            <div class="flex flex-row items-center justify-center h-full space-x-5 animate-pulse">
                                <div class="flex flex-col space-y-3">
                                    <div class="h-6 bg-gray-300 rounded-md w-36 ">
                                    </div>
                                    <div class="w-24 h-3 bg-gray-300 rounded-md ">
                                    </div>
                                </div>
                            </div>
                        </div>
                        </nav>
                        <div class="absolute bottom-0 mt-10 mb-2 flex">
                            <button disabled type="button" class="py-2 px-4 flex text-md justify-center items-center  bg-green-800 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                <BsPlus className="" />
                                New Collection
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        
    )
}