import DocumentMeta from "react-document-meta";

import rocket from "../assets/img/hero.png"
import Navbar from "../components/Navbar";
import arch from "../assets/img/bts-1.png"
import e2e from "../assets/img/ran-2.png"

const meta = {
  title: "Nomadic Dashboard",
  description: "Manage OpenBTS and srsRAN",
  canonical: "https://passmd.online",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "Password, secrets"
    }
  }
};
function RAN (){
    return (
      <>
      <div>
          <DocumentMeta {...meta} />
        </div>
        <Navbar />
        {/* Hero */}
        <div className="relative overflow-hidden ">
        <img
                src="https://images.unsplash.com/photo-1511233744044-194342066754?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="absolute inset-0 object-cover w-full h-full"
                alt=""
            />
        <div className="hidden sm:block sm:relative sm:inset-0" aria-hidden="true">
          <svg
            className="absolute top-3 right-3 transform translate-x-1/2 mb-48 text-teal-300 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
            width={364}
            height={384}
            viewBox="0 0 364 384"
            fill="none"
          >
            <defs>
              <pattern
                id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} fill="currentColor" />
              </pattern>
            </defs>
            <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
          </svg>
        </div>
        <div className="relative  pb-16 sm:pb-24">
        
  
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto w-full">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-8xl">
                      
                      Nomadic RAN
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Manage, monitor and maintain your srsRAN instances and data with ease. Get added
                      assurance of end-to-end encryption while deploying your app secrets with the Nomadic Project.
                    </p>
                  </div>
                </div>
                <div className="hidden lg:block mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                  <div className=" sm:max-w-full sm:w-full sm:mx-auto sm:rounded-lg  sm:overflow-hidden">
                    <img src={rocket} alt="rocket" className=" "/>
                 </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      
      {/* Feature Encryption */}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8 lg:py-20 ">
        <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
        <div>
            <img
              className="w-full"
              src={arch}
              alt="End-to-End Encryption"
            />
          </div>
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                System{' '}
                <span className="inline-block text-green-600">
                  Architecture
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae. explicabo.
              </p>
            </div>
            <div className="grid gap-8 row-gap-8 sm:grid-cols-2">
              <div>
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                  <svg
                    className="w-10 h-10 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  I'll be sure to note that in my log
                </h6>
                <p className="text-sm text-gray-900">
                  Dingy I'm tellin' you rhubaahb Bangah Jo-Jeezly got in a gaum
                  Powrtland stove up dooryahd
                </p>
              </div>
              <div>
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
                  <svg
                    className="w-10 h-10 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
                <h6 className="mb-2 font-semibold leading-5">
                  Iterative approaches to corporate strategy
                </h6>
                <p className="text-sm text-gray-900">
                  A slice of heaven. O for awesome, this chocka full cuzzie is as
                  rip-off as a cracker.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature Colab */}
      <section className="text-gray-100">
        <div className="container flex flex-col-reverse mx-auto lg:flex-row items-center">
       
          <div className="flex flex-col px-6 py-8 space-y-6 rounded-lg sm:p-8 lg:p-12 lg:w-1/2 xl:w-2/5 h-2/5 bg-violet-400 text-gray-900">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                How to Install
            </h2>
            <div className="flex space-x-2 sm:space-x-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leading-snug">Lorem ipsum dolor sit amet</p>
                <p className="leading-snug">Praesentium ea et neque distinctio quas eius repudiandae quaerat obcaecati voluptatem similique!</p>
              </div>
            </div>
            <div className="flex space-x-2 sm:space-x-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leading-snug">Lorem ipsum dolor sit amet</p>
                <p className="leading-snug">Praesentium ea et neque distinctio quas eius repudiandae quaerat obcaecati voluptatem similique!</p>
              </div>
            </div>
            <div className="flex space-x-2 sm:space-x-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
              </svg>
              <div className="space-y-2">
                <p className="text-lg font-medium leading-snug">Lorem ipsum dolor sit amet</p>
                <p className="leading-snug">Praesentium ea et neque distinctio quas eius repudiandae quaerat obcaecati voluptatem similique!</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 xl:w-3/5 ">
            <div className="flex items-center justify-center p-4 md:p-8 lg:p-12">
              <img src={e2e} alt="colaborate" className="sm:min-h-80" />
            </div>
          </div>
        </div>
      </section>
      </>
    )
}

export default RAN