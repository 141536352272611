import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { BiTime } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { MdUpdate } from "react-icons/md";
import { Link } from "react-router-dom";
import DjangoDateTime from "../../components/elements/DjangoDateTime";
import Greeting from "../../components/elements/Greeting";

const queryClient = new QueryClient()

function CollectionHome () {
    return (
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    )
}
function App() {
    const [cookies] = useCookies([])

    const { isLoading, error, data } = useQuery(['stats'], () =>
        fetch(process.env.REACT_APP_BACKEND_URL + '/stats', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': cookies['pdid']
            }
        })
        .then(res => res.json())
    )

    if (isLoading) return "loading"
    if (error) return "Could not loading resource"

    

    const handleClick = (name) => {
        sessionStorage.setItem("active_collection", name)
    }
    return (
        <>
        
            
            <div class="h-screen px-2 py-8 mt-6 overflow-hidden md:px-4 md:mt-8 bg-white rounded-xl">
                <h1 class="text-4xl font-semibold text-gray-800 dark:text-black">
                    <Greeting />, {data.first_name ? data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1,) : null}
                </h1>
                <h2 class="text-gray-400 text-md">
                    Here&#x27;s what&#x27;s happening with your account today.
                </h2>
                <div class="flex flex-col items-center w-full my-6 space-y-4 md:space-x-4 md:space-y-0 md:flex-row">
                    
                    <div class="flex items-center w-full space-x-4 md:w-1/2">
                        <div class="w-1/2">
                            <div class="relative w-full px-4 py-6 bg-blue-50 rounded-lg ">
                                <p class="text-2xl font-bold text-black ">
                                    {data.collections}
                                </p>
                                <p class="text-sm text-gray-400">
                                    Collections
                                </p>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="relative w-full px-4 py-6 bg-blue-50 rounded-lg ">
                                <p class="text-2xl font-bold text-black ">
                                    {data.shared_collections}
                                </p>
                                <p class="text-sm text-gray-400">
                                    Shared Collections
                                </p>
                                <span class="absolute p-4 text-green-800 rounded-full top-2 right-4">
                                    <FaUsers />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {data.recent_collections  ? 
                <>
                <div class="flex items-center space-x-4">
                    <button class="flex items-center  px-4 py-2 text-gray-400 border border-gray-300 rounded-r-full  rounded-l-full text-md">
                        <BiTime className="mr-2" />
                        Recent
                    </button>
                </div>
                    <div class="grid grid-cols-1 gap-4 my-4 md:grid-cols-2 lg:grid-cols-3">
                       {data.recent_collections.map((collection) => (
                       <div class="w-full">
                            <div class="relative w-full px-4 py-6 bg-blue-50 rounded-lg ">
                                <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                    Collection
                                </p>
                                <div class="flex flex-wrap items-end my-6 space-x-2 text-5xl font-bold text-black ">
                                    <Link to={'/collections/' + collection.unique_id} class="" onClick={() => handleClick(collection.name)}>
                                        {collection.name}
                                    </Link>
                                </div>
                                <div class="text-gray-700">
                                    <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                        <p>
                                            Created
                                        </p>
                                        <div class="flex items-end text-xs">
                                            <DjangoDateTime time={collection.date_created} />
                                            <span class="flex items-center">
                                                <svg width="20" fill="currentColor" height="20" class="h-3 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-12">
                                        <p>
                                        Updated
                                        </p>
                                        <div class="flex items-end text-xs">
                                            <DjangoDateTime time={collection.date_updated} />
                                            <span class="flex items-center">
                                                <MdUpdate className="text-sm text-green-500" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                    </div>
                    </>
                 : null}
            </div>
        </>
    )
}

export default CollectionHome