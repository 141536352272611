import { BsCheckCircleFill } from "react-icons/bs";

export default function VerificationSuccess() {
    return (
        <>
        
            <div className="relative max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
            <div className="flex w-full justify-center">
            <BsCheckCircleFill className="mt-2 text-4xl text-center font-extrabold text-green-600 tracking-tight sm:text-5xl" />
            </div>
            <p className="text-sm mt-2 font-semibold text-white text-opacity-50 uppercase tracking-wide">Verification Complete</p>
            <p className="mt-2 text-lg font-medium text-white text-opacity-50">
               Your email has been verified.
            </p>
            <div className="mt-6">
                <a
                href="/auth/signin"
                className="inline-flex items-center mr-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-gray-300 bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
                >
                Signin
                </a>
                <a
                href="/"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-gray-300 bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
                >
                Home
                </a>
            </div>
            </div>
        </>
    )
}