import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client'
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loading from './components/elements/Loading';
import Landing from './layouts/Landing';
import Auth from './layouts/Auth';
import Errorpage from './layouts/ErrorPage';
import Collections from './layouts/Collections';
import BTS from './layouts/BTS';
import RAN from './layouts/RAN';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <CookiesProvider>
    <Suspense fallback={<Loading />} />
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Landing />} />
        <Route exact path='/nomadicbts' element={<BTS />} />
        <Route exact path='/nomadicran' element={<RAN />} />
        <Route  path="auth/*"  element={<Auth />} />
        <Route path="collections/*" element={<Collections />} />
        <Route  path="*"  element={<Errorpage />} />
      </Routes>
    </BrowserRouter>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
